.custom-arrow-next,
.custom-arrow-prev {
  color:#000000;
  background: #ffffff;
  padding: 7px 12px;
  font-size: 1.25rem;
  background-image: none!important;
  width: auto;
  height: auto;
}
.custom-arrow-next {
  right: 0!important;
}
.custom-arrow-prev {
  left: 0!important;
}

.custom-arrow-next::after,
.custom-arrow-prev::after {
  content: none;
}