.sustain figure img {
  max-width: 100%;
}

@media (max-width: 321px) {
  .top__header .top-header-text {
    margin-left: 5px !important;
  }
}

@media (max-width: 991px) {
  body .top__header .main-menu .navbar .carticon_sticky {
    margin-top: 0 !important;
    margin-right: 30px !important;
    top: 50% !important;
    transform: translate(0, -8%) !important;
    right: 50px !important;
  }
}
.main_news_content.card-columns {
  column-gap: 1.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 768px) {
  .main_news_content.card-columns {
    column-gap: 1.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr ;
  }
}

@media (max-width: 480px) {
  .main_news_content.card-columns {
    column-gap: 1.25rem;
    display: grid;
    grid-template-columns: 1fr;
  }
}

a {
  color: red;
  text-decoration: none;
  &:hover {
    color: red;
    text-decoration: none;
  }
}

.simple-news-content li {
  font-family: 'Lato',sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: .3px;
  font-weight: 300;
}

.products__filter--wrapper .actions li {
  height: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}

.top__header--menu ul li a, .top__header .main-menu .navbar-nav li a{
  color: #212529 !important;
}
