.login__modal-wrapper {
  .text-red {
    a {
      cursor: pointer;
    }
  }
}
#login-modal {
  .modal-header {
    border: none;
    background: #f4f4f4!important;
  }
  .modal-content {
    border: none;
    background-color: transparent;
  }
  .modal-body {
    background: #f4f4f4!important;
    label {
      color: #a3a3a3;
      font-family: "Lato", sans-serif;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0.5px;
    }
    input[type="text"], input[type="email"], input[type="password"] {
      border-radius: 0;
      background: #ffffff;
      outline: none;
      &:focus {
        border-color: #e0e9f3;
        box-shadow: none;
      }
    }
    input[type="checkbox"] {
      border-radius: 0;
      background: #ffffff;
      outline: none;
    }
  }
  .form-submit-btn {
    .btn {
      background-color: #FF0000;
      border-radius: 0;
      outline: none;
      border:none;
      text-align: center;
      text-transform: uppercase;
      &:focus {
        border:none;
        box-shadow: none;
      }
    }
  }
  .social-login-icons {
    p { color: #a3a3a3; font-weight: bold; font-size: 15px; }
    ul {
      li {
        a {
          text-decoration: none;
        }
      }
    }
  }
  .modal-footer {
    background: transparent;
    .forgot-password {
      display: block;
      width: 100%;
      p {
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
}