.search-overlay {
  height: 100%;
  width: 100%;
  display: block;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  background-color: rgba(20, 20, 20, 0.75);
  .overlay-content {
    position: relative;
    top: 46%;
    width: 80%;
    text-align: center;
    margin: 30px auto 0 auto;
  }
  .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    cursor: pointer;
    color: #b0b0b0;
  }
}
.search-icon {
  color: #b0b0b0!important;
  a {
    cursor: pointer;
    font-size: 18px;
  }
}
