.termin-arrow-navigation {
  display: inline-block;
  cursor: pointer;
}
.left-arrow-index {
  position: absolute;
  left: 0;
  top: 57%;
  cursor: pointer;
}
.right-arrow-index {
  position: absolute;
  right: 0;
  top: 57%;
  cursor: pointer;
}

.termin__swiper_content .swiper-slide {
  max-width: 240px;
}
.closebtn-dtl {
  position: fixed;
  background: none;
  border: none;
  top: 20px;
  right: 30px;
  width: 30px;
  height: 30px;
  z-index: 9999;
  color: #ffffff;
  svg {
    font-size: 25px;
  }
}
#detail-overlay {
  .modal-content {
    border: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .modal-header {
    border: none;
    padding: 0;
    background: #575757 !important;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    .header-arrows {
      position: absolute;
      top: 5px;
      right: 25px;
      button {
        background: none;
        box-shadow: none;
        border: none;
        color: #ffffff;
        padding: 0;
        &:focus {
          outline: none;
        }
        &.left-arrow {
        }
        &.right-arrow {
        }
        svg {
          font-size: 30px;
        }
      }
    }
  }
  .modal-body {
    padding: 0;
    h1 {
      color: #000000;
      font-size: 50px;
      letter-spacing: 0.5px;
      line-height: 55px;
      margin-bottom: 30px;
    }
    p {
      color: #000000;
      font-size: 15px;
      font-weight: 300;
      line-height: 22px;
    }
    span {
      float: left;
      width: 100%;
      margin-bottom: 8px;
    }
    img.img-fluid {
      float: right;
    }
    .event-buttons {
      position: absolute;
      bottom: 0;
      .btn-red {
        width: auto;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        margin-right: 1.5em;
      }
    }
  }
  .modal-footer {
    display: none;
  }
}
