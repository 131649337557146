@import "compiled";
@import "swiper.min";
@import "custom-css";
@import "termin";
@import "loginModal";
@import "reviereSlider";
@import "partnerMap";
@import "segelsport";
@import "kontaktMap";
@import "search";
@import "productSlider";
@import "bugs";
@import "video";
