.product-slider-wrapper {
  display: flex;
  flex-direction: row;
  max-height: 500px;
}

.product-thumbs {
  max-width: 25%;
  @media (max-width: 768px) {
    display: none;
  }
  img {
    height: 100%;
  }
  .swiper-slide {
    opacity: 0.4;
  }
  .swiper-slide-active {
    opacity: 1;
  }
}

.product-slide {
  max-width: 100%;
  @media (min-width: 768px) {
    max-width: 75%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .swiper-pagination-bullet {
    background: #fff;
    opacity: .7;
  }
  .swiper-pagination-bullet-active {
    background: #fff;
    opacity: 1;
  }

}

.product-slide,.product-thumbs {
  &.swiper-container {
    width: 100%;
    margin: 15px;
  }

}

.product-slider-wrapper {
  @media (min-width: 768px) {
    .swiper-pagination {
      display: none;
    }
  }
}