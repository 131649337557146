.btn.disabled, .btn:disabled {
  opacity: 0.65 !important;
  cursor: not-allowed !important;
}

.hidden {
  display: none !important;
}

.address-select input[type="radio"] {
  position: relative;
  z-index: 2;
}

.address-select .address-data {
  margin-top: -25px;
  padding-left: 18px;
}

.order-review .address-select .address-data {
  margin-top: 0px;
  padding-left: 0px;
}

.address-select .actions {
  padding-left: 18px;
}

.parsley-errors-list,
.errors {
  padding: 0 !important;
  color: #f5463b !important;
  list-style: none;
  margin: 5px 0 0 0;
}

.parsley-error {
  border-color: #f5463b !important;
  color: #f5463b !important;
}

.zoomImg {
  background: #f9f9f9;
}

.breadcrumb.checkout_procces_wrapper {
  padding-left: 0;
  padding-right: 0;
  background: #fff;
}

.breadcrumb.checkout_procces_wrapper .breadcrumb-item {
  padding-left: 5px;
  color: rgba(0, 0, 0, .33);
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: .5px;
  line-height: 28px;
}

.breadcrumb.checkout_procces_wrapper .breadcrumb-item a {
  text-decoration: none;
  color: rgba(0, 0, 0, .33);
}

.breadcrumb.checkout_procces_wrapper .breadcrumb-item.is_active a {
  color: #000;
}

.breadcrumb.checkout_procces_wrapper .breadcrumb-item + .breadcrumb-item:before {
  display: inline-block;
  padding-right: 5px;
  color: rgba(0, 0, 0, .33);
  content: ">";
  font-family: Lato, sans-serif;
  font-weight: 700;
}

body .top__header .main-menu .navbar .carticon_sticky {
  /*display: block;*/
  top: -155px;
  right: 68px !important;
  transition: all 0s linear;
}

.top__header--menu ul {
  margin-right: 2em;
  padding-right: 15px;
}

@media (max-width: 991px) {
  body .top__header .main-menu .navbar .carticon_sticky {
    margin-top: -2px;
    margin-right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    right: 35px !important;
  }
}

.termin-popup {
  position: absolute;
  z-index: 1000;
  top: 290px;
  right: -800px;
  max-width: 400px;
  background-color: #87200d;
  mix-blend-mode: hard-light;

  -webkit-animation: slideIn 1s forwards;
  -webkit-animation-delay: 2s;

  /*for firefox*/
  -moz-animation: slideIn 1s forwards;
  -moz-animation-delay: 2s;

  /* for opera*/
  -o-animation: slideIn 1s forwards;
  -o-animation-delay: 2s;

  /* Standard syntax*/
  animation: slideIn 1s forwards;
  animation-delay: 2s;
}

.termin-popup .content-section {
  background: white;
  padding: 30px 30px 0;
}

.termin-popup .content-section .title {
  font-family: Cormorant, sans-serif;
  font-weight: 500;
  padding-bottom: 18px;
  font-size: 40px;
  line-height: 45px;
  border-bottom: 1px solid #000;
}

.termin-popup .content-section .date {
  padding: 15px 0;
  font-size: 18px;
  font-weight: 400;
}

.termin-popup .link {
  text-align: center;
}

.termin-popup .link a {
  font-family: Cormorant, sans-serif;
  font-weight: 700;
  font-size: 17px;
  color: #FFF;
  padding: 10px;
  display: inline-block;
  width: 100%;
  font-style: italic;
}


@-webkit-keyframes slideIn {
  100% {
    right: 0;
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}








/* Additional css for courses */
.course-form-container{
  max-width: 950px;
  margin: 5px auto 60px auto ;
}

.course-form-container hr{
  border-top: 1px solid black;
  margin-top: 40px;
  margin-bottom: 25px;
  opacity: 0.5;
}

.course-type-buttons-form-group button
{
  width:48%;
  display: inline-block;
  background-color: #ababab;
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
  height: 40px;
  font-weight: 300;
  border: none;
}

.course-type-buttons-form-group button:last-child
{
  float:right;
}

.course-type-buttons-form-group button:hover{
  background-color: #575757;
}

.course-type-buttons-form-group button:focus{
  background-color: #575757;
}
.course-type-buttons-form-group button.active{
  background-color: #575757;
}

.course-form-group{
  padding-top: 10px;
  display: flex;
  color: #9e9e9e;
  font-weight: bold;
}

.course-form-group.first{
  padding-top: 50px;
}

.group-4 {
  width: 37%;
  margin-right: 3%;
}

.group-8 {
  width: 80%;
  margin-right: 3%;
}

.group-2 {
  width: 19%;
}
.group-2:last-child {
  width: 20%;
}


.group-3 {
  width: 28%;
  margin-right: 3%;
}

.group-5 {
  width: 48%;
  margin-right: 3%;
}

.group-5:last-child {
  width: 49%;
  float:right;
  margin-right: 0;
}

.group-7 {
  width: 69%;
  float:right;
  margin-right: 0;
}

.course-form-group .submit-button {
  width: 100%;
  background-color: #ff0000;
  color: #fff;
  border: none;
  height: 40px;
  text-transform: uppercase;
}

.course-form-group .disabled {
  opacity: 0.5;
}

.course-form-group input {
  color: #9e9e9e;
  /*border: none; */
}

.course-form-group input:read-only {
  background-color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .course-form-group{
    display: block;
  }
  .group-4, .group-8, .group-2, .group-2:last-child, .group-3, .group-5, .group-5:last-child, .group-7  {
    width: 100%;
  }
  .course-form-container hr{
    float: left;
    width: 100%;
  }
}

.course-close {
  position: absolute;
  right: 5px;
  width: 20px;
  height: 20px;
  opacity: 0.5;
}
.course-close:hover {
  opacity: 1;
}
.course-close:before, .course-close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 21px;
  width: 1px;
  background-color: red;
}
.course-close:before {
  transform: rotate(45deg);
}
.course-close:after {
  transform: rotate(-45deg);
}

.course-form-group .title-img{
  position: relative;
  bottom: 2px;
  padding-right:8px;
}

.course-not-logged-div{
  padding-top: 20px;
}

.course-not-logged-div .text-red a {
  font-weight: bolder !important;
  font-style: normal !important;
}




/* Additional css for user profile */
.profile-form-container{
  max-width: 950px;
  margin: 5px auto 60px auto ;
}

.profile-form-container h3{
  color: #000;
  font-family: Cormorant,sans-serif!important;
  font-size: 50px;
  padding: 20px 0;
  line-height: 55px;
}

.profile-form-container hr{
  border-top: 1px solid black;
  margin-top: 40px;
  margin-bottom: 25px;
  opacity: 0.5;
}

.profile-type-buttons-form-group button
{
  width:48%;
  display: inline-block;
  background-color: #ababab;
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
  height: 40px;
  font-weight: 300;
  border: none;
}

.profile-type-buttons-form-group button:last-child
{
  float:right;
}

.profile-type-buttons-form-group button:hover{
  background-color: #575757;
}

.profile-type-buttons-form-group button:focus{
  background-color: #575757;
}
.profile-type-buttons-form-group button.active{
  background-color: #575757;
}

.profile-form-group{
  padding-top: 10px;
  display: flex;
  color: #9e9e9e;
  font-weight: bold;
}

.profile-form-container .group-4 {
  width: 37%;
  margin-right: 3%;
}

.profile-form-container .group-4:last-child  {
  margin-right: auto;
}

.profile-form-container .group-8 {
  width: 80%;
  margin-right: 3%;
}

.profile-form-container .group-8:last-child {
  margin-right: auto;
}

.profile-form-container .group-2 {
  width: 19%;
}

.profile-form-container .group-2:first-child {
  width: 20%;
  margin-right: 3%;
}

.profile-form-container .group-3 {
  width: 28%;
  margin-right: 3%;
}

.profile-form-container .group-5 {
  width: 48%;
  margin-right: 3%;
}

.profile-form-container .group-5:last-child {
  width: 49%;
  float:right;
  margin-right: 0;
}

.profile-form-container .group-7 {
  width: 69%;
  float:right;
  margin-right: 0;
}

.profile-form-group .submit-button {
  float:right;
  width: 26%;
  background-color: #ff0000;
  color: #fff;
  border: none;
  height: 40px;
  text-transform: uppercase;
  margin: auto;
  font-size: 14px;
  margin-left: 30px;
}

.profile-form-group .cancel-button {
  float:right;
  width: 26%;
  background-color: #9e9e9e;
  color: #fff;
  border: none;
  height: 40px;
  text-transform: uppercase;
  margin-left: auto;
  font-weight: 100;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
}

.profile-form-group .cancel-button:hover{
  text-decoration: none;
}

.profile-form-group.block {
  display:block;
}

.profile-form-group .disabled {
  opacity: 0.5;
}

.profile-form-group input {
  color: #9e9e9e;
  border: none;
}

.profile-form-group input:not([type="submit"]):read-only {
  background-color: #ffffff;
}

.profile-form-group select {
  border: none;
}

.signup{
  padding-top: 30px;
  padding-bottom: 50px;
}
.signup .profile-form-group{
  padding-top: 30px;
}
.login-signup-p {
  text-align: center;
}

.login-signup-p a {
  color: #FF0000;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .profile-form-group{
    display: block;
  }
  .group-4, .group-8, .group-2, .group-3, .group-5, .group-7 {
    width: 100%;
  }
  .profile-form-container hr{
    float: left;
    width: 100%;
  }
}

.profile-close {
  position: absolute;
  right: 5px;
  width: 20px;
  height: 20px;
  opacity: 0.5;
}
.profile-close:hover {
  opacity: 1;
}
.profile-close:before, .profile-close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 21px;
  width: 1px;
  background-color: red;
}
.profile-close:before {
  transform: rotate(45deg);
}
.profile-close:after {
  transform: rotate(-45deg);
}

.profile-form-group .title-img{
  position: relative;
  bottom: 2px;
  padding-right:8px;
}

.profile-not-logged-div{
  padding-top: 20px;
}

.profile-not-logged-div .text-red a {
  font-weight: bolder !important;
  font-style: normal !important;
}

.profile-form-container .profile-error{
  color: #fff;
  background-color: #ffafaf;
  width: 100%;
  padding: 10px;
  font-weight: 400;
  border-radius: 3px;
  margin: 10px auto;
}

.profile-form-container .profile-success{
  color: #fff;
  background-color: #59ab74;
  width: 100%;
  padding: 10px;
  font-weight: 400;
  border-radius: 3px;
  margin: 10px auto;
}

/* Additional css for login */

.login-div, .login-div .login__modal-wrapper, .login-div .login__modal-wrapper p {
  display: inline;
}

.login-ce-span {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .profile-form-container{
    margin: 5px 10px 60px 10px ;
  }

  .profile-form-container.signup{
    margin: 5px 10px 130px 10px ;
  }

  .profile-form-group{
    display: block;
  }
  .profile-form-container .group-4,
  .profile-form-container .group-8,
  .profile-form-container .group-2,
  .profile-form-container .group-2:last-child,
  .profile-form-container .group-3,
  .profile-form-container .group-5,
  .profile-form-container .group-5:last-child,
  .profile-form-container .group-7 {
    width: 100%;
  }
  .profile-form-container hr{
    float: left;
    width: 100%;
  }
}

.search-overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  background-color: rgba(20, 20, 20, 0.75);
  .overlay-content {
    position: relative;
    top: 46%;
    width: 80%;
    text-align: center;
    margin: 30px auto 0 auto;
  }
  .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    cursor: pointer;
    color: #b0b0b0;
  }
}
.search-icon {
  color: #b0b0b0!important;
  a {
    cursor: pointer;
    font-size: 18px;
  }
}
.logo-wrapper a {
  margin-top: -25px;
}
a[role=button] {
  cursor: pointer;
}

.dropdown-menu li a {
  display: inline-block;
  width: 100%;
  clear: both;
  font-size: 16px!important;
  font-weight: 300!important;
  color: #000 !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent !important;
  border: 0;
}